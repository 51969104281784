<template>
  <CardLayout class="p-4 flex flex-col">
    <div class="border-b border-gray-200 flex">
      <div class="flex flex-col justify-between">
        <div class="font-semibold uppercase text-black">Kulud</div>
        <div class="space-y-0.5 mb-2">
          <div class="text-gray-500 font-semibold uppercase text-sm">
            Eelarve
          </div>
          <div class="text-primary-red font-bold text-xl">
            {{ kuludTotalStr }}
          </div>
        </div>
      </div>
      <div class="flex flex-col justify-center items-end flex-grow">
        <div class="text-gray-500 font-semibold uppercase text-sm">
          Eelarve täitmine
        </div>
      </div>
      <CircularProgressBar
        :value="kuludProgressPercentage"
        :colors="['#D56484', '#F7E0E6']"
      />
    </div>
    <div>
      <BarChart
        class="mr-5 ml-5"
        :values="data.kuludChartData"
        primary-color="#D56484"
        secondary-color="#F7E0E6"
        sorted="ascending"
      />
    </div>
  </CardLayout>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import CardLayout from "@/components/layout/CardLayout.vue";
import CircularProgressBar from "@/components/charts/CircularProgressBar.vue";
import BarChart from "@/components/charts/BarChart.vue";
import { EelarveAndmikKoond } from "@/firebase/parseData";
import { toLocaleFR } from "@/firebase/utils";

export default defineComponent({
  name: "KoondVaadeKuludChart",
  components: { BarChart, CircularProgressBar, CardLayout },
  props: {
    data: {
      type: Object as PropType<EelarveAndmikKoond>,
      required: true,
    },
    kuludProgressPercentage: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const kuludTotalStr = computed(() => toLocaleFR(props.data.kuludTotal, 0));
    return { kuludTotalStr };
  },
});
</script>

<style scoped></style>
