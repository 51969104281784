
import { defineComponent } from "vue";
import ToggleArticleFeatures from "@/components/common/vaadeheader/ToggleArticleFeatures.vue";
import DateAsOf from "@/components/common/vaadeheader/DateAsOf.vue";

export default defineComponent({
  name: "VaadeHeader",
  components: { DateAsOf, ToggleArticleFeatures },
  props: {
    // expecting yyyy-mm-dd
    selectedDate: {
      type: String,
      required: false,
    },
  },
  setup() {
    return {};
  },
});
