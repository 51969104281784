<template>
  <VaadeGuard :routeName="RouteName.KOOND_VAADE">
    <VaadeHeader :selected-date="parsedData?.selectedDate" />
    <div
      class="w-full xl:grid xl:grid-cols-2 gap-4 space-y-6 xl:space-y-0 px-6 pt-3 pb-6"
    >
      <div>
        <BaseTable
          :data="data"
          :expanded-code="expandedCode"
          @onExpand="onExpand"
        >
          <template #footer v-if="footerData">
            <tr>
              <td
                class="px-4 py-2 h-full total-row bg-primary-light-teal bg-opacity-50 rounded-bl-md"
              >
                <div
                  class="ml-10 text-left text-primary-teal font-bold tracking-wider text-sm uppercase"
                >
                  {{ footerData?.footerName }}
                </div>
              </td>
              <td
                class="px-4 py-2 total-row bg-primary-light-teal bg-opacity-50 text-primary-teal font-semibold text-right"
              >
                {{ footerData?.eelarveStr }}
              </td>
              <td
                class="px-4 py-2 total-row bg-primary-light-teal bg-opacity-50 text-primary-teal font-semibold text-right"
              >
                {{ footerData?.taitmineStr }}
              </td>
              <td
                class="px-4 py-2 total-row bg-primary-light-teal bg-opacity-50 text-primary-teal font-semibold text-right rounded-br-md"
              >
                {{ footerData?.percentageStr }}
              </td>
            </tr>
          </template>
        </BaseTable>
      </div>
      <div class="space-y-6">
        <KoondVaadeTuludChart
          :data="parsedData"
          :tuludProgressPercentage="tuludProgressPercentage"
        />
        <KoondVaadeKuludChart
          :data="parsedData"
          :kuludProgressPercentage="kuludProgressPercentage"
        />
      </div>
    </div>
  </VaadeGuard>
</template>

<script lang="ts">
import { computed, DeepReadonly, defineComponent, ref } from "vue";
import { EelarveAndmikKoond } from "@/firebase/parseData";
import { toLocaleFR } from "@/firebase/utils";
import BaseTable from "@/components/table/BaseTable.vue";
import KoondVaadeTuludChart from "@/components/koondvaade/tuludchart/KoondVaadeTuludChart.vue";
import KoondVaadeKuludChart from "@/components/koondvaade/kuludchart/KoondVaadeKuludChart.vue";
import { useMunicipalityStore } from "@/store/MunicipalityStore";
import { EelarveAndmik } from "@/firebase/parse/types";
import VaadeHeader from "@/components/common/vaadeheader/VaadeHeader.vue";
import VaadeGuard from "@/components/common/VaadeGuard.vue";
import { RouteName } from "@/router/types";

export default defineComponent({
  components: {
    KoondVaadeKuludChart,
    KoondVaadeTuludChart,
    BaseTable,
    VaadeHeader,
    VaadeGuard,
  },
  setup() {
    const municipalityStore = useMunicipalityStore();
    const municipalityState = municipalityStore.getState();
    const parsedData = computed<DeepReadonly<EelarveAndmikKoond> | null>(
      () => municipalityState.koondVaade
    );

    const data = computed(() =>
      parsedData.value?.data.slice(0, parsedData.value?.data.length - 1)
    );

    const footerData = computed<{
      footerName: string;
      eelarveStr: string;
      taitmineStr: string;
      percentageStr: string;
    } | null>(() => {
      const footerItem = parsedData.value?.data.slice(-1)[0];
      if (!footerItem) return null;
      const footerName = footerItem.header.title;
      const eelarve = footerItem.elements[0]?.title || 0;
      const taitmine = footerItem.elements[1]?.title || 0;
      const percentage = eelarve === 0 ? 0 : taitmine / eelarve;
      return {
        footerName: footerName,
        eelarveStr: toLocaleFR(eelarve, 0),
        taitmineStr: toLocaleFR(taitmine, 0),
        percentageStr: `${(percentage * 100).toFixed(1)} %`,
      };
    });

    const expandedCode = ref<string | undefined>(undefined);
    const onExpand = (code: string | undefined) =>
      (expandedCode.value = expandedCode.value === code ? undefined : code);

    const tuludProgressPercentage = computed(() =>
      getProgressPercentage(municipalityState.tulud)
    );

    const kuludProgressPercentage = computed(() =>
      getProgressPercentage(municipalityState.kulud)
    );

    const getProgressPercentage = (
      data: DeepReadonly<EelarveAndmik> | null
    ): number => {
      if (!data) return 0;
      const usedBudget = data.usedBudgetTotal || 0;
      const budgetTotal = data.budgetTotal || 0;
      return budgetTotal ? (usedBudget / budgetTotal) * 100 : 0;
    };

    return {
      parsedData,
      expandedCode,
      onExpand,
      data,
      footerData,
      tuludProgressPercentage,
      kuludProgressPercentage,
      RouteName,
    };
  },
});
</script>
