
import { computed, defineComponent } from "vue";
import Toggle from "@/components/common/Toggle.vue";
import { useMunicipalityStore } from "@/store/MunicipalityStore";

export default defineComponent({
  name: "ToggleArticleFeatures",
  components: { Toggle },
  setup() {
    const municipalityStore = useMunicipalityStore();
    const municipalityState = municipalityStore.getState();
    const onArticleFeaturesChange = (active: boolean) =>
      municipalityStore.setArticleFeatures(active);
    const isArticleFeaturesActive = computed(
      () => municipalityState.articleFeatures
    );
    return { onArticleFeaturesChange, isArticleFeaturesActive };
  },
});
