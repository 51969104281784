
import { computed, defineComponent, PropType } from "vue";
import CardLayout from "@/components/layout/CardLayout.vue";
import CircularProgressBar from "@/components/charts/CircularProgressBar.vue";
import BarChart from "@/components/charts/BarChart.vue";
import { EelarveAndmikKoond } from "@/firebase/parseData";
import { toLocaleFR } from "@/firebase/utils";

export default defineComponent({
  name: "KoondVaadeKuludChart",
  components: { BarChart, CircularProgressBar, CardLayout },
  props: {
    data: {
      type: Object as PropType<EelarveAndmikKoond>,
      required: true,
    },
    kuludProgressPercentage: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const kuludTotalStr = computed(() => toLocaleFR(props.data.kuludTotal, 0));
    return { kuludTotalStr };
  },
});
