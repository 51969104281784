
import { defineComponent, onMounted, PropType, ref, watch } from "vue";
import { select, scaleBand, scaleLinear, axisBottom, max } from "d3";
import useResizeObserver from "@/firebase/resizeObserver";
import { BarChartData } from "@/components/charts/chart-interfaces";
import { truncateString } from "@/components/charts/chart-utils";

export default defineComponent({
  props: {
    sorted: {
      type: String,
      required: true,
      default: "",
    },
    values: {
      type: Array as PropType<BarChartData[]>,
      required: true,
      default: () => [],
    },
    primaryColor: {
      type: String,
      required: false,
      default: "#2F989F",
    },
    secondaryColor: {
      type: String,
      required: false,
      default: "#D5EAEC",
    },
  },
  setup(props) {
    const svgRef = ref();
    const { resizeState, resizeRef } = useResizeObserver();

    onMounted(() => {
      watch([resizeState, props], ([resizeState, props]) => {
        // margins and size
        const { height, width } = resizeState;
        const margin = { top: 60, right: 20, bottom: 20, left: 40 };
        const fontSize = 12.5;
        const legendfontSize = 14;
        const labelFontSize = 16;
        const normalizationCoeficient = 1000000;

        // create space for chart
        const svg = select(svgRef.value);
        svg.attr("width", width);
        svg.attr("height", height);

        svg.selectAll("g").remove();

        const canvas = svg.append("g");
        canvas.attr("transform", `translate(${margin.left}, ${margin.right})`);

        let chartData = [...props.values.map((v) => ({ ...v }))];

        chartData.forEach((e) => {
          e.type = truncateString(e.type, 28);
        });

        if (props.sorted === "ascending") {
          chartData.sort((a, b) => a.totalValue - b.totalValue);
        } else if (props.sorted === "descending") {
          chartData.sort((a, b) => b.totalValue - a.totalValue);
        }

        // define maximum number of totalValues to create the scale of numbers
        const absMax = max(chartData, (d) => Math.abs(d.totalValue)) as number;

        // define x scale
        const x = scaleBand()
          .domain(
            chartData.map((d) => {
              return d.type;
            })
          )
          .rangeRound([margin.left, width - margin.right])
          .paddingInner(0.1);

        // define y scale
        const y = scaleLinear()
          .domain([-absMax, absMax])
          .nice()
          .range([height - margin.bottom, margin.top]);

        // define background bars
        const backgroundBars = svg
          .append("g")
          .selectAll("emptyBar")
          .data(chartData)
          .enter();

        // generate background bars
        backgroundBars
          .append("rect")
          .attr("class", (d: BarChartData) =>
            d.totalValue < 0 ? "bar negative" : "bar positive"
          )
          // .attr("y", (d: BarChartData) => y(Math.max(0, d.totalValue)))
          .attr("y", () => y(0))
          .attr("x", (d: BarChartData) => (x(d.type) as unknown) as string)
          // .attr("height", (d) => Math.abs(y(d.totalValue) - y(0)))
          .attr("height", 0)
          .attr("width", x.bandwidth())
          .style("fill", props.secondaryColor)
          .transition()
          .duration(800)
          .attr("y", (d: BarChartData) => y(Math.max(0, d.totalValue)))
          .attr("height", (d) => Math.abs(y(d.totalValue) - y(0)))
          .delay((_, i) => i * 100);

        // generate background bars labels
        backgroundBars
          .append("text")
          .attr("class", "label")
          .attr("y", y(0))
          .attr("x", (d: BarChartData) => x(d.type) ?? 0)
          .text((d: BarChartData) => (d.totalValue / 1000000).toFixed(2) + " M")
          .attr("text-anchor", "middle")
          .attr("transform", () => `translate(${x.bandwidth() / 2})`)
          .style("font-size", fontSize + "px")
          .style("font-weight", "700")
          .transition()
          .duration(800)
          .attr("y", (d: BarChartData) => {
            if (d.totalValue > 0) {
              return y(Math.max(0, d.totalValue)) - 3;
            } else {
              return y(Math.min(0, d.totalValue)) + 10;
            }
          })
          .delay((_, i) => i * 100);

        // define foreground bars
        const foregroundBars = svg
          .append("g")
          .selectAll(".bar")
          .data(chartData)
          .enter();

        // generate foreground bars
        foregroundBars
          .append("rect")
          .attr("class", (d: BarChartData) =>
            d.currentValue < 0 ? "bar negative" : "bar positive"
          )
          .attr("y", () => y(0))
          .attr("x", (d: BarChartData) => (x(d.type) as unknown) as string)
          .attr("height", 0)
          .attr("width", x.bandwidth())
          .style("fill", props.primaryColor)
          .transition()
          .duration(800)
          .attr("y", (d: BarChartData) => y(Math.max(0, d.currentValue)))
          .attr("height", (d) => Math.abs(y(d.currentValue) - y(0)))
          .delay((_, i) => i * 100);

        // generate foreground bars labels
        foregroundBars
          .append("text")
          .attr("class", "label")
          .attr("y", (d) => {
            if (d.currentValue > 0) {
              if (d.currentValue > normalizationCoeficient) {
                return height / 2 - 2;
              }
              return y(d.currentValue) - 2;
            } else {
              if (d.currentValue < -normalizationCoeficient) {
                return height / 2 + 10;
              }
              return y(d.currentValue) + 10;
            }
          })
          .attr("x", (d: BarChartData) => x(d.type) ?? 0)
          .attr("text-anchor", "middle")
          .attr("transform", () => `translate(${x.bandwidth() / 2})`)
          .style("font-size", fontSize + "px")
          .style("font-weight", "700")
          .style("fill", (d) => {
            if (d.currentValue > 0) {
              if (d.currentValue > normalizationCoeficient) {
                return "white";
              }
              return props.primaryColor;
            } else {
              if (d.currentValue < -normalizationCoeficient) {
                return "white";
              }
              return props.primaryColor;
            }
          });

        // draw x axis and tick labels
        svg
          .append("g")
          .attr("class", "x axis")
          .attr("transform", `translate(0, ${height / 2 + 20})`) // add coefficient (40) for better centering
          .call(axisBottom(x).tickSize(0))
          .selectAll(".tick")
          .select("text")
          .attr("y", 10)
          .attr("x", 10)
          .attr("transform", "translate(-10,10) rotate(-45)")
          .style("text-anchor", "end")
          .style("font-size", labelFontSize + "px")
          .style("font-weight", "600")
          .filter((d, i) => chartData[i].totalValue < 0)
          .attr("y", -20)
          .attr("x", 20)
          .attr("transform", "translate(-10,10) rotate(-45)")
          .style("text-anchor", "start")
          .style("font-size", labelFontSize + "px");

        // generate legend
        // eelarve circle
        canvas
          .append("g")
          .append("circle")
          .attr("cx", width - margin.right - margin.left - 120)
          .attr("cy", 0)
          .attr("r", 6)
          .style("fill", props.secondaryColor);

        // eelarve text
        canvas
          .append("g")
          .append("text")
          .attr("x", width - margin.right - margin.left - 110)
          .attr("y", 1)
          .text("Eelarve")
          .style("font-size", legendfontSize)
          .style("font-weight", "400")
          .attr("alignment-baseline", "middle");

        // eelarve taitmine circle
        canvas
          .append("g")
          .append("circle")
          .attr("cx", width - margin.right - margin.left - 120)
          .attr("cy", 20)
          .attr("r", 6)
          .style("fill", props.primaryColor);

        // eelarve taitmine text
        canvas
          .append("g")
          .append("text")
          .attr("x", width - margin.right - margin.left - 110)
          .attr("y", 21)
          .text("Eelarve täitmine")
          .style("font-size", legendfontSize)
          .style("font-weight", "400")
          .attr("alignment-baseline", "middle");
      });
    });

    return { svgRef, resizeRef };
  },
});
