
import { defineComponent } from "vue";

export default defineComponent({
  name: "Toggle",
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
  emits: {
    onChange: (active: unknown) => typeof active === "boolean",
  },
  setup(props, { emit }) {
    const onChange = () => emit("onChange", !props.active);
    return { onChange };
  },
});
